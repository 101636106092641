import { Title, TitleContent } from '@les-sherpas/sherpas-toolbox';

import StudentReviews from '@/components/LandingMozza/LandingSeo/common/StudentReviews/StudentReviews';
import SEOFormattedMessage from '@/generic/SEOFormattedMessage/SEOFormattedMessage';

export default function SeoStudentReviews({
  reviews,
}: {
  reviews: Title & { items: TitleContent[] };
}) {
  return (
    <StudentReviews
      title={
        reviews.title ? (
          <SEOFormattedMessage
            id="studentReviews.overloadedTitle"
            defaultMessage={reviews.title}
          />
        ) : undefined
      }
      reviews={
        reviews.items.length > 0
          ? reviews.items.map(({ title, content }, index) => ({
              id: index,
              title: (
                <SEOFormattedMessage
                  id={`studentReviews.reviews.overloadedTitle${index}`}
                  defaultMessage={title}
                />
              ),
              description: (
                <SEOFormattedMessage
                  id={`studentReviews.reviews.overloadedContent${index}`}
                  defaultMessage={content}
                />
              ),
            }))
          : undefined
      }
    />
  );
}
