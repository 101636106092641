import { FC } from 'react';
import {
  PublicGetCityPageResponse,
  SearchResult,
} from '@les-sherpas/sherpas-toolbox';

import Article from '@/components/LandingMozza/common/Article/Article';
import useGoToPageWithEvent from '@/components/LandingMozza/common/hooks/useGoToPageWithEvent';
import GTMEventName from '@/components/LandingMozza/LandingSeo/common/constants';
import SeoHero from '@/components/LandingMozza/LandingSeo/common/overloads/SeoHero/SeoHero';
import SeoLandingFaq from '@/components/LandingMozza/LandingSeo/common/overloads/SeoLandingFaq/SeoLandingFaq';
import SeoRaiseIncome from '@/components/LandingMozza/LandingSeo/common/overloads/SeoRaiseIncome/SeoRaiseIncome';
import SeoStartingSteps from '@/components/LandingMozza/LandingSeo/common/overloads/SeoStartingSteps/SeoStartingSteps';
import SeoStudentReviews from '@/components/LandingMozza/LandingSeo/common/overloads/SeoStudentReviews/SeoStudentReviews';
import SeoTeachers from '@/components/LandingMozza/LandingSeo/common/overloads/SeoTeachers/SeoTeachers';
import SeoTextBlock from '@/components/LandingMozza/LandingSeo/common/overloads/SeoTextBlock/SeoTextBlock';
import SeoLinksCityTemplate from '@/components/LandingMozza/LandingSeo/LandingCity/SeoLinksCityTemplate/SeoLinksCityTemplate';
import PaddingContainer from '@/components/PaddingContainer/PaddingContainer';
import { SEARCH } from '@/data/navbar/navButtons';

import Breadcrumbs from './Breadcrumbs/Breadcrumbs';

type Props = PublicGetCityPageResponse & {
  announces: SearchResult[];
};

const LandingCity: FC<Props> = ({
  announces,
  template,
  cityAddress,
  links,
}) => {
  const goToPageWithEvent = useGoToPageWithEvent();
  const searchQuery = {
    address: cityAddress.formattedAddress,
  };
  const gtmEventData = {
    template: 'city',
    city: cityAddress.city,
    address: cityAddress.formattedAddress,
  };

  return (
    <>
      <Article backgroundColor="greenGradient" verticalPadding={64}>
        <PaddingContainer>
          <div>
            <Breadcrumbs city={cityAddress.city} />
            <SeoHero hero={template.hero} />
          </div>
        </PaddingContainer>
      </Article>
      <Article backgroundColor="white" verticalPadding={64}>
        <PaddingContainer>
          <SeoTeachers
            teachers={template.teachers}
            announces={announces}
            handleRedirection={() => {
              goToPageWithEvent({
                route: SEARCH,
                query: searchQuery,
                event: GTMEventName,
                data: {
                  ...gtmEventData,
                  component: 'teachers.searchButton',
                },
              });
            }}
          />
        </PaddingContainer>
      </Article>
      <Article backgroundColor="white" verticalPadding={64}>
        <PaddingContainer>
          <SeoRaiseIncome />
        </PaddingContainer>
      </Article>
      <Article backgroundColor="white" withOverflowHidden={false}>
        <PaddingContainer>
          <SeoStartingSteps
            stepper={template.stepper}
            handleRedirection={() => {
              goToPageWithEvent({
                route: SEARCH,
                query: searchQuery,
                event: GTMEventName,
                data: {
                  ...gtmEventData,
                  component: 'stepper.searchButton',
                },
              });
            }}
          />
        </PaddingContainer>
      </Article>
      <Article
        itemScope
        itemType="https://schema.org/LocalBusiness"
        backgroundColor="blue"
        verticalPadding={120}
      >
        <PaddingContainer>
          <SeoStudentReviews reviews={template.reviews} />
        </PaddingContainer>
      </Article>
      <Article
        backgroundColor="white"
        itemScope
        itemType="https://schema.org/FAQPage"
        verticalPadding={64}
      >
        <PaddingContainer>
          <SeoLandingFaq
            qa={template.qa}
            handleRedirection={() => {
              goToPageWithEvent({
                route: SEARCH,
                query: searchQuery,
                event: GTMEventName,
                data: {
                  ...gtmEventData,
                  component: 'qa.searchButton',
                },
              });
            }}
          />
        </PaddingContainer>
      </Article>
      <Article backgroundColor="green" verticalPadding={64}>
        <PaddingContainer>
          <SeoLinksCityTemplate links={links} />
        </PaddingContainer>
      </Article>
      <Article backgroundColor="white">
        <PaddingContainer>
          <SeoTextBlock seo={template.seo} />
        </PaddingContainer>
      </Article>
    </>
  );
};

export default LandingCity;
