import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette, breakpoints }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column-reverse',
    gap: rem(24),
    backgroundColor: palette.common.white,
    padding: `${rem(24)} ${rem(16)}`,
    alignItems: 'center',
    borderRadius: rem(16),
    justifyContent: 'space-between',
    [breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      padding: rem(32),
    },
  },
  divider: {
    marginTop: rem(40),
  },
  description: {
    marginTop: rem(16),
    color: palette.neutral[500],
    marginBottom: rem(24),
  },
  image: {
    position: 'relative',
    borderRadius: rem(16),
    objectFit: 'cover',
  },
  googleRate: {
    justifyContent: 'center',
    [breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
  },
  reviewItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  imageContainer: {
    position: 'relative',
    minWidth: '80vw',
    [breakpoints.up('md')]: {
      minWidth: rem(308),
    },
    height: 380,
  },
}));
