import { FormattedMessage, useIntl } from 'react-intl';

import RaiseIncome from '@/components/LandingMozza/common/RaiseIncome/RaiseIncome';
import { SEARCH } from '@/data/navbar/navButtons';

import messages from './messages';

export default function SeoRaiseIncome() {
  const { formatMessage } = useIntl();

  return (
    <RaiseIncome
      title={<FormattedMessage {...messages.title} />}
      description={<FormattedMessage {...messages.description} />}
      buttonLabel={formatMessage(messages.buttonLabel)}
      privateCoursePageURL={SEARCH}
    />
  );
}
