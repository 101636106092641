import { FC, ReactElement } from 'react';
import { Divider, Typography } from '@mui/material';
import Image from 'next/image';

import RatingGoogle from '@/components/LandingMozza/common/RatingGoogle/RatingGoogle';
import useStyles from '@/components/LandingMozza/LandingSeo/common/StudentReviews/ReviewsCarousel/ReviewItem/styles';
import { getImageFromCloudImage } from '@/utils/cloudFrontLoader';

export interface ReviewItemProps {
  id: number;
  title: string | ReactElement;
  description: string | ReactElement;
}

const nameImageMap = [
  {
    name: 'Alexandre',
    image:
      'https://sherpas-prod.s3.eu-west-3.amazonaws.com/landing/seo-student-pages/reviews/Alexandre.webp',
  },
  {
    name: 'Andolie',
    image:
      'https://sherpas-prod.s3.eu-west-3.amazonaws.com/landing/seo-student-pages/reviews/Andolie.webp',
  },
  {
    name: 'Maude',
    image:
      'https://sherpas-prod.s3.eu-west-3.amazonaws.com/landing/seo-student-pages/reviews/Maude.webp',
  },
  {
    name: 'Nathan',
    image:
      'https://sherpas-prod.s3.eu-west-3.amazonaws.com/landing/seo-student-pages/reviews/Nathan.webp',
  },
  {
    name: 'Wissem',
    image:
      'https://sherpas-prod.s3.eu-west-3.amazonaws.com/landing/seo-student-pages/reviews/Wissem.webp',
  },
];

const ReviewItem: FC<ReviewItemProps> = ({ id, title, description }) => {
  const { classes } = useStyles();

  return (
    <div
      className={classes.container}
      itemProp="review"
      itemScope
      itemType="https://schema.org/Review"
    >
      <div className={classes.reviewItem}>
        <RatingGoogle
          className={classes.googleRate}
          gtmEvent="gmb_seoLanding"
        />
        <Divider className={classes.divider} />
        <Typography variant="titleXs" itemProp="headline">
          {title}
        </Typography>
        <Typography
          variant="bodyXl"
          component="p"
          className={classes.description}
          itemProp="reviewBody"
        >
          {description}
        </Typography>
        <span itemProp="author" itemScope itemType="https://schema.org/Person">
          <meta itemProp="image" content={nameImageMap[id].image} />
          <Typography itemProp="name" variant="bodyXl">
            {nameImageMap[id].name}
          </Typography>
        </span>
        <div
          itemProp="reviewRating"
          itemScope
          itemType="https://schema.org/Rating"
        >
          <meta itemProp="ratingValue" content="5" />
          <meta itemProp="bestRating" content="5" />
        </div>
      </div>
      <div className={classes.imageContainer}>
        <Image
          src={nameImageMap[id].image}
          fill
          sizes="(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 25vw"
          loader={getImageFromCloudImage}
          quality={100}
          className={classes.image}
          alt={`Photo de ${nameImageMap[id].name}`}
        />
      </div>
    </div>
  );
};

export default ReviewItem;
