import { FC, ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import ArticleHeader from '@/components/LandingMozza/common/ArticleHeader/ArticleHeader';
import SectionTitle from '@/components/LandingMozza/common/SectionTitle/SectionTitle';
import EnterChunk from '@/components/ReactIntlChunks/EnterChunk';

import { ReviewItemProps } from './ReviewsCarousel/ReviewItem/ReviewItem';
import ReviewsCarousel from './ReviewsCarousel/ReviewsCarousel';
import messages from './messages';
import { descriptions, titles } from './reviewsMessages';

import useStyles from './styles';

interface StudentReviewsProps {
  title?: string | ReactElement;
  reviews?: ReviewItemProps[];
}

const StudentReviews: FC<StudentReviewsProps> = ({ title, reviews }) => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();
  const defaultReviews = new Array(5).fill(0).map(
    (_, index) =>
      ({
        id: index,
        title: formatMessage({ ...titles[index] }),
        description: formatMessage({
          ...descriptions[index],
        }),
      }) satisfies ReviewItemProps
  );

  return (
    <div className={classes.container}>
      <meta itemProp="name" content="Soutien scolaire Les Sherpas" />
      <div
        itemProp="address"
        itemScope
        itemType="https://schema.org/PostalAddress"
      >
        <meta itemProp="streetAddress" content="6 rue Général Clergerie" />
        <meta itemProp="addressLocality" content="Paris" />
        <meta itemProp="addressRegion" content="Île-de-France" />
        <meta itemProp="postalCode" content="75116" />
        <div
          itemProp="addressCountry"
          itemScope
          itemType="https://schema.org/Country"
        >
          <meta itemProp="name" content="France" />
        </div>
      </div>
      <ArticleHeader>
        <SectionTitle>
          {title ?? (
            <FormattedMessage
              {...messages.title}
              values={{ enter: EnterChunk }}
            />
          )}
        </SectionTitle>
      </ArticleHeader>
      <ReviewsCarousel reviews={reviews ?? defaultReviews} />
      <div
        itemProp="aggregateRating"
        itemScope
        itemType="https://schema.org/AggregateRating"
      >
        <meta itemProp="ratingValue" content="4.8" />
        <meta itemProp="bestRating" content="5" />
        <meta itemProp="reviewCount" content="180" />
      </div>
    </div>
  );
};

export default StudentReviews;
