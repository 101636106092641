import { FC, PropsWithChildren, useState } from 'react';
import ReactSlickSlider from 'react-slick';

import useStyles from '@/shared/Carousel/styles';

import 'slick-carousel/slick/slick.css';

type SliderProps = {
  slidesToShow?: number;
  spaceBetweenSlides?: number;
  dotClass?: string;
  activeDotClass?: string;
};
const Carousel: FC<PropsWithChildren<SliderProps>> = ({
  children,
  slidesToShow = 1,
  spaceBetweenSlides = 28,
  dotClass,
  activeDotClass,
}) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const { classes, cx } = useStyles({ spaceBetweenSlides });

  const dots = (index: number) => (
    <span
      className={cx({
        [classes.dot]: true,
        [dotClass]: !!dotClass,
        [classes.activeDot]: index === currentSlideIndex,
        [activeDotClass]: !!activeDotClass && index === currentSlideIndex,
      })}
    />
  );

  return (
    <ReactSlickSlider
      className={classes.container}
      dotsClass={classes.dots}
      customPaging={dots}
      appendDots={(dots) => <ul>{dots}</ul>}
      afterChange={setCurrentSlideIndex}
      slidesToShow={slidesToShow}
      slidesToScroll={1}
      infinite={false}
      autoplay={false}
      dots
      arrows={false}
      speed={500}
    >
      {children}
    </ReactSlickSlider>
  );
};

export default Carousel;
