import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

type StyleProps = {
  spaceBetweenSlides: number;
};

export default makeStyles<StyleProps>()(
  ({ palette }, { spaceBetweenSlides }) => ({
    container: {
      '.slick-slide > *': {
        marginRight: `${rem(spaceBetweenSlides)}`,
      },
    },
    dots: {
      display: 'flex',
      listStyle: 'none',
      justifyContent: 'center',
      alignItems: 'center',
      gap: rem(8),
      padding: 0,
    },
    dot: {
      display: 'block',
      cursor: 'pointer',
      width: `${rem(16)}`,
      height: `${rem(16)}`,
      borderRadius: '100%',
      backgroundColor: palette.neutral[800],
    },
    activeDot: {
      opacity: 1,
      backgroundColor: palette.neutral[500],
    },
  })
);
