import { defineMessages } from 'react-intl';

export const descriptions = defineMessages({
  0: {
    id: 'landingSeo.studentReviews.review1.description',
    defaultMessage: `Je cherchais des cours de #Matière# en ligne pour mon petit cousin il y a 3 semaines. Et je suis pas du tout familier avec ce monde des prépas, c'est sur recommandation que je les ai contactés. Résultat : Lui et ses parents sont ravis !`,
  },
  1: {
    id: 'landingSeo.studentReviews.review2.description',
    defaultMessage: `Cours de #Matière# en ligne très organisés et efficaces. J’ai pu augmenter mes résultats grâce aux Sherpas ! ☺️`,
  },
  2: {
    id: 'landingSeo.studentReviews.review3.description',
    defaultMessage: `Une excellente plateforme. J'ai pu trouver un professeur particulier de #Matière# en ligne répondant à mes attentes et à mon budget. L'équipe pédagogique est très réactive et à l'écoute.`,
  },
  3: {
    id: 'landingSeo.studentReviews.review4.description',
    defaultMessage: `Une excellente plateforme. J'ai pu trouver un professeur particulier de #Matière# en ligne répondant à mes attentes et à mon budget. L'équipe pédagogique est très réactive et à l'écoute.`,
  },
  4: {
    id: 'landingSeo.studentReviews.review5.description',
    defaultMessage: `Une excellente plateforme. J'ai pu trouver un professeur particulier de #Matière# en ligne répondant à mes attentes et à mon budget. L'équipe pédagogique est très réactive et à l'écoute.`,
  },
});

export const titles = defineMessages({
  0: {
    id: 'landingSeo.studentReviews.review1.title',
    defaultMessage: `Je cherchais des cours de #Matière# en ligne pour mon petit cousin il y a 3 semaines. Et je suis pas du tout familier avec ce monde des prépas, c'est sur recommandation que je les ai contactés. Résultat : Lui et ses parents sont ravis !`,
  },
  1: {
    id: 'landingSeo.studentReviews.review2.title',
    defaultMessage: `Cours de #Matière# en ligne très organisés et efficaces. J’ai pu augmenter mes résultats grâce aux Sherpas ! ☺️`,
  },
  2: {
    id: 'landingSeo.studentReviews.review3.title',
    defaultMessage: `Une excellente plateforme. J'ai pu trouver un professeur particulier de #Matière# en ligne répondant à mes attentes et à mon budget. L'équipe pédagogique est très réactive et à l'écoute.`,
  },
  3: {
    id: 'landingSeo.studentReviews.review4.title',
    defaultMessage: `Une excellente plateforme. J'ai pu trouver un professeur particulier de #Matière# en ligne répondant à mes attentes et à mon budget. L'équipe pédagogique est très réactive et à l'écoute.`,
  },
  4: {
    id: 'landingSeo.studentReviews.review5.title',
    defaultMessage: `Une excellente plateforme. J'ai pu trouver un professeur particulier de #Matière# en ligne répondant à mes attentes et à mon budget. L'équipe pédagogique est très réactive et à l'écoute.`,
  },
});
