import { FC } from 'react';

import ReviewItem, {
  ReviewItemProps,
} from '@/components/LandingMozza/LandingSeo/common/StudentReviews/ReviewsCarousel/ReviewItem/ReviewItem';
import Carousel from '@/shared/Carousel/Carousel';
import { useIsMobile } from '@/utils/useResponsive';

import useStyles from './styles';

interface ReviewsSliderProps {
  reviews: ReviewItemProps[];
}

const ReviewsCarousel: FC<ReviewsSliderProps> = ({ reviews }) => {
  const { classes } = useStyles();
  const isMobile = useIsMobile();
  return (
    <Carousel
      dotClass={classes.dot}
      activeDotClass={classes.activeDot}
      spaceBetweenSlides={isMobile ? 0 : 20}
    >
      {reviews.map(({ id, title, description }) => (
        <ReviewItem id={id} key={id} title={title} description={description} />
      ))}
    </Carousel>
  );
};

export default ReviewsCarousel;
