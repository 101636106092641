import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette }) => ({
  dot: {
    backgroundColor: palette.supporting.sky[800],
  },
  activeDot: {
    backgroundColor: palette.supporting.sky[500],
  },
}));
