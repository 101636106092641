import { FC } from 'react';
import { useIntl } from 'react-intl';
import { PublicGetCityPageResponse } from '@les-sherpas/sherpas-toolbox';

import SeoHeader from '@/components/LandingMozza/common/SeoHeader/SeoHeader';
import SeoTable from '@/components/LandingMozza/common/SeoTable/SeoTable';

import messages from './messages';

type Props = {
  links: PublicGetCityPageResponse['links'];
};

const SeoLinksCityTemplate: FC<Props> = ({ links }) => {
  const { formatMessage } = useIntl();
  const { subjects, cities } = links;
  return (
    <>
      <SeoHeader />
      <SeoTable
        links={subjects}
        title={formatMessage(messages.titleSubject)}
        type="subject"
      />
      <SeoTable
        links={cities}
        title={formatMessage(messages.titleCities)}
        type="location"
      />
    </>
  );
};

export default SeoLinksCityTemplate;
