import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'seo.raiseIncome.title',
    defaultMessage: `Crédit d'impôt : ne payez que 50% du prix de vos cours à domicile`,
  },
  description: {
    id: 'seo.raiseIncome.description',
    defaultMessage: `Le crédit d'impôt pour les cours particuliers à domicile en France permet aux contribuables de bénéficier d'une réduction d'impôt équivalente à 50% des dépenses engagées pour des services à la personne, y compris le soutien scolaire, dans la limite de 12 000 euros par an, pouvant être majorée sous certaines conditions.`,
  },
  buttonLabel: {
    id: 'seo.raiseIncome.buttonLabel',
    defaultMessage: `Profiter de la réduction d'impôt`,
  },
});
